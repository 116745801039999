import React, { useEffect, useState } from 'react'
import { Col, Nav, Row, Tab } from 'react-bootstrap'
import Header from '../components/Header'
import Footer from '../components/Footer'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getRolesAssign } from '../redux/actionCreator'
import { rolesAssign_lists } from '../redux/commonReducer'
import { API_BASE_URL } from '../constants/configuration'
import AssignmentTab from '../components/AssignmentTab'

function Assignments() {
    const { t } = useTranslation();
    const [language, setLanguage] = useState('')
    const [imageChange, setImageChange] = useState('')
    const dispatch = useDispatch()

    // 
    const [title, setTitle] = useState("");

    useEffect(() => {
        document.title = title;
    }, [title]);
    // DATAS
    const rolesAssign = useSelector(rolesAssign_lists)

    useEffect(() => {
        dispatch(getRolesAssign())
    }, [])

    useEffect(() => {
        setTitle(t('html_title.assignments'))
        const lang = localStorage.getItem('lang')
        setLanguage(lang)
    }, [localStorage.getItem('lang')])


    useEffect(() => {
        const lang = localStorage.getItem('lang')
        setLanguage(lang);
    }, []);
    

    useEffect(() => {
        if(rolesAssign?.length){
            setImageChange(rolesAssign[0]?.banner_name)
        }
    }, [rolesAssign]);


    const handleTabSelect = (key) => {
        setImageChange(rolesAssign[key]?.banner_name)
    };

    return (
        <>
            <Header />
            <section className="innerpage__header">
                <div className="container sm-pad-0">
                    <div className="banner__image">
                        <img src={API_BASE_URL + 'RolesAssign/' + imageChange} className='w-100' alt="" />
                        <div className="contents">
                            <div className="row">
                                <div className="col-md-12 p-0">
                                    <div className="header__title bottomPad">
                                        <h2>{t('roles_assignments')}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="roles__assignments bg-default">
                <div className="container">
                    <Tab.Container id="left-tabs-example" defaultActiveKey='0' onSelect={handleTabSelect}>
                        <Nav variant="pills" className="flex-column">
                            <div className="assignment__tabItems">
                                <Row className='nav-scroll'>
                                    {
                                        rolesAssign?.map((ele, i) => {
                                            return (
                                                <Col md={2} sm={44} className='col-tab-3'>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={`${i}`}>
                                                            <div className="assignment__tab">
                                                                <div className="icon"><img src={API_BASE_URL + 'RolesAssign/' + ele?.body_icon_name} alt="" /></div>
                                                                <div className="title">
                                                                    <h5>{language == 'en' ? ele?.sub_title_en : ele?.sub_title_ar}</h5>
                                                                    <h3>{language == 'en' ? ele?.title_en : ele?.title_ar}</h3>
                                                                </div>
                                                            </div>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </Col>
                                            )
                                        })
                                    }

                                </Row>
                            </div>
                        </Nav>



                        <Tab.Content>
                            {
                                rolesAssign?.map((ele, i) => {
                                    return (
                                        <Tab.Pane eventKey={`${i}`}>
                                            <AssignmentTab data={ele} />
                                        </Tab.Pane>
                                    )
                                })
                            }
                        </Tab.Content>
                    </Tab.Container>

                </div>
            </section>
            <Footer />

        </>
    )
}

export default Assignments