import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import i18n from "i18next";
import { useState } from "react";
import { useEffect } from "react";

function Header() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [langToggle, setLangtoggle] = useState("");
  const [language, setLanguage] = useState("en");
  let current = localStorage.getItem("lang");
  let body = document.body;

  useEffect(() => {
    if (!localStorage.getItem("lang")) {
      localStorage.setItem("lang", "en");
    }
  }, []);

  const openHeader = (e) => {
    e.preventDefault();
    body.classList.add("box-collapse-open");
    body.classList.remove("box-collapse-closed");
  };

  const closeHeader = (e) => {
    e.preventDefault();
    body.classList.remove("box-collapse-open");
    body.classList.add("box-collapse-closed");
  };
  useEffect(() => {
    if (window?.location?.pathname === "/") {
      navigate(`/en/`);
    }
    const path = window.location.pathname.includes("/ar");
    if (path) {
      localStorage.setItem("lang", "ar");
    } else {
      localStorage.setItem("lang", "en");
    }
  }, []);

  const chnageLan = (lang) => {
    setLangtoggle(!langToggle);
    localStorage.setItem("lang", lang);
    setLanguage(lang);

    if (lang === "ar") {
      document.body.className = "rtl";
    } else {
      document.body.className = "ltr";
    }
    let currentLanguage = lang == "en" ? "ar" : "en";
    console.log(lang, location.pathname.replace(currentLanguage, lang), "123");
    navigate(location.pathname.replace(currentLanguage, lang));
    // if(location.pathname?.split("/")?.length === 1){
    //     navigate(`/en/`)
    // }else{
    //     navigate(`/${lang}/${location.pathname?.split("/")?.slice(-1)?.[0]}`)
    // }
  };
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    i18n.changeLanguage(current);
    if (current === "ar") {
      document.body.className = "rtl";
    } else {
      document.body.className = "ltr";
    }
  }, [current]);

  return (
    <>
      <div>
        <div className="click-closed" />
        <div className="box-collapse">
          <span
            className="close-box-collapse left-boxed fas fa-times"
            onClick={closeHeader}
          />
          <div className="box-collapse-wrap">
            <div className="container">
              <div className="navlink__items">
                <div className="row">
                  <ul>
                    <li onClick={closeHeader}>
                      <Link to={`/${i18n.language}`}>{t("home")}</Link>
                    </li>
                    <li onClick={closeHeader}>
                      <Link to={`/${i18n.language}/about`}>{t("about")}</Link>
                    </li>
                    <li onClick={closeHeader}>
                      <Link to={`/${i18n.language}/assignments`}>
                        {t("roles")}
                      </Link>
                    </li>
                    <li onClick={closeHeader}>
                      <Link to={`/${i18n.language}/jurisdictions`}>
                        {t("jurisdictions")}
                      </Link>
                    </li>
                    <li onClick={closeHeader}>
                      <Link to={`/${i18n.language}/contact`}>
                        {t("contact")}
                      </Link>
                    </li>
                  </ul>
                  <div className="social__links">
                    <ul>
                      <li>
                        <a
                          href="https://www.instagram.com/escdubai/"
                          target="_blank"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/escdubai">
                          <i className="fab fa-twitter" target="_blank"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/escdubai/">
                          <i className="fab fa-linkedin-in" target="_blank"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/ESCODXB">
                          <i className="fab fa-facebook-f" target="_blank"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav className="navbar navbar-default navbar-trans navbar-expand-lg fixed-top">
          <div className="container navbar__border">
            <div className="logo__side">
              <Link className="navbar-brand" to={`/${i18n.language}/`}>
                {" "}
                <img src="/assets/img/logo.svg" />{" "}
              </Link>
              <button
                type="button"
                className="navbar-toggle-box navbar-toggle-box-collapse"
                onClick={openHeader}
                data-bs-toggle="collapse"
                data-bs-target="#navbarTogglerDemo01"
              >
                <i className="fas fa-bars" />
              </button>
              <div className="social__links">
                <ul>
                  <li>
                    <a
                      href="https://www.instagram.com/escdubai/"
                      target="_blank"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/escdubai">
                      <i className="fab fa-twitter" target="_blank"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/escdubai/">
                      <i className="fab fa-linkedin-in" target="_blank"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/ESCODXB">
                      <i className="fab fa-facebook-f" target="_blank"></i>
                    </a>
                  </li>
                  {/* <li><Link to={'/report_incident'}> <button className='report-incident'>
                                        <img src='/assets/img/icons/Group 11799.svg' />
                                        Report Incident
                                    </button></Link></li> */}
                </ul>
              </div>
            </div>

            <div className="language">
              {current == "en" ? (
                <div
                  className="language__switch"
                  onClick={() => chnageLan("ar")}
                >
                  <Link>ع</Link>
                </div>
              ) : (
                <div
                  className="language__switch"
                  onClick={() => chnageLan("en")}
                >
                  <Link>En</Link>
                </div>
              )}

              <Link
                className="navbar-brand economy__logo"
                to={`/${i18n.language}/`}
              >
                <img src="/assets/img/right-logo.svg" />{" "}
              </Link>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

export default Header;
