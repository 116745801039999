import React from 'react'
import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation();
    return (
        <section className='footer__section'>
            <div className="container">
                <div className="row a" >
                    <div className="col-md-6">
                        <div className="social__links">
                            <ul>
                                <li><a href="https://www.instagram.com/escdubai/" target='_blank'><i className='fab fa-instagram'></i></a></li>
                                <li><a href="https://twitter.com/escdubai" target='_blank'><i className='fab fa-twitter'></i></a></li>
                                <li><a href="https://www.linkedin.com/company/escdubai" target='_blank'><i className='fab fa-linkedin-in'></i></a></li>
                                <li><a href="https://www.facebook.com/ESCODXB" target='_blank'><i className='fab fa-facebook-f'></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="copyright">
                            <p>{t('copyright')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer