import React from 'react'

export default function Page1({ setStage, setInputs, inputs }) {
    return (
        <div className='Page1'>
            <section className='content container'>
                <article>
                    <h2>Introduction</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non magna porttitor sem suscipit commodo. Fusce cursus euismod sapien. Vestibulum sit amet dolor tellus. Suspendisse luctus feugiat sagittis. Curabitur tellus massa, fermentum nec orci sit amet, luctus viverra lacus. Integer quam nisl, posuere vitae risus accumsan, ullamcorper tempus ligula. Aenean porttitor ante vel nisl laoreet, et semper neque pharetra. Cras consectetur nisi erat, et volutpat ante ullamcorper sed.
                    </p>
                    <p>
                        Nunc justo dolor, pretium nec sem id, maximus pulvinar quam. Nulla accumsan tincidunt dui vitae finibus. Donec at libero consequat, elementum tellus sed, varius elit. Suspendisse fringilla nec erat id scelerisque. Maecenas vel justo consectetur, luctus augue id, iaculis turpis. Nullam non imperdiet justo, quis tincidunt turpis. Sed et fermentum libero. Sed non dapibus massa, vel pellentesque dolor. Pellentesque faucibus imperdiet dui et malesuada. Sed at mi at nunc tincidunt sagittis vel hendrerit ex. Proin sodales scelerisque nulla, ac tempor ligula pulvinar eu. Maecenas nisl ante, venenatis sed velit ut, tempor rutrum eros. Aliquam non facilisis quam. Sed fringilla justo lectus. Etiam porttitor mauris a est gravida, id consequat ipsum consectetur.
                    </p>
                </article>
                <article>
                    <h2>Terms and Conditions</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non magna porttitor sem suscipit commodo. Fusce cursus euismod sapien. Vestibulum sit amet dolor tellus. Suspendisse luctus feugiat sagittis. Curabitur tellus massa, fermentum nec orci sit amet, luctus viverra lacus. Integer quam nisl, posuere vitae risus accumsan, ullamcorper tempus ligula. Aenean porttitor ante vel nisl laoreet, et semper neque pharetra. Cras consectetur nisi erat, et volutpat ante ullamcorper sed.
                    </p>
                    <p>
                        Nunc justo dolor, pretium nec sem id, maximus pulvinar quam. Nulla accumsan tincidunt dui vitae finibus. Donec at libero consequat, elementum tellus sed, varius elit. Suspendisse fringilla nec erat id scelerisque. Maecenas vel justo consectetur, luctus augue id, iaculis turpis. Nullam non imperdiet justo, quis tincidunt turpis. Sed et fermentum libero. Sed non dapibus massa, vel pellentesque dolor. Pellentesque faucibus imperdiet dui et malesuada. Sed at mi at nunc tincidunt sagittis vel hendrerit ex. Proin sodales scelerisque nulla, ac tempor ligula pulvinar eu. Maecenas nisl ante, venenatis sed velit ut, tempor rutrum eros. Aliquam non facilisis quam. Sed fringilla justo lectus. Etiam porttitor mauris a est gravida, id consequat ipsum consectetur.

                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non magna porttitor sem suscipit commodo. Fusce cursus euismod sapien. Vestibulum sit amet dolor tellus. Suspendisse luctus feugiat sagittis. Curabitur tellus massa, fermentum nec orci sit amet, luctus viverra lacus. Integer quam nisl, posuere vitae risus accumsan, ullamcorper tempus ligula. Aenean porttitor ante vel nisl laoreet, et semper neque pharetra. Cras consectetur nisi erat, et volutpat ante ullamcorper sed.
                    </p>
                    <p>
                        Nunc justo dolor, pretium nec sem id, maximus pulvinar quam. Nulla accumsan tincidunt dui vitae finibus. Donec at libero consequat, elementum tellus sed, varius elit. Suspendisse fringilla nec erat id scelerisque. Maecenas vel justo consectetur, luctus augue id, iaculis turpis. Nullam non imperdiet justo, quis tincidunt turpis. Sed et fermentum libero. Sed non dapibus massa, vel pellentesque dolor. Pellentesque faucibus imperdiet dui et malesuada. Sed at mi at nunc tincidunt sagittis vel hendrerit ex. Proin sodales scelerisque nulla, ac tempor ligula pulvinar eu. Maecenas nisl ante, venenatis sed velit ut, tempor rutrum eros. Aliquam non facilisis quam. Sed fringilla justo lectus. Etiam porttitor mauris a est gravida, id consequat ipsum consectetur.
                    </p>
                </article>
            </section>
            <section className='form-fill-up container'>
                <div>
                    <label htmlFor="conditions">
                        <input id='conditions' type="checkbox" checked={inputs.agree_conditions} onChange={(e) => setInputs((s) => ({ ...s, agree_conditions: e.target.checked }))} />
                        <div>
                            I have read and agreed to all the informations mentioned above
                        </div>
                    </label>
                </div>
                <div className='button-div'>
                    <button className={'next-button' + (inputs.agree_conditions ? '' : ' disabled')} onClick={() => setStage(2)} disabled={!inputs.agree_conditions}>
                        Continue
                        <img className='right' src="./assets/img/icons/Icon awesome-arrow-right.svg" alt="" />
                    </button>
                </div>
            </section>
        </div>
    )
}
