function RegexMatch(text, regex) {
    return new Promise((resolve, reject) => {
        if (regex.test(text.trim())) {
            resolve()
        } else {
            reject('Invalid input')
        }
    })
}

function lengthCheck(text, maxLength) {
    return new Promise((resolve, reject) => {
        if (text.trim().length <= maxLength) {
            resolve()
        } else {
            reject(`You have exceeded the maximum limit of ${maxLength} characters`)
        }
    })
}

export const onOTPrequestValidation = async (inputs, setErrors) => {
    let status = false
    return Promise.all([
        await lengthCheck(inputs.first_name || '', 40).then(() => status = true).catch((err) => (status = false, setErrors((s) => ({ ...s, first_name: err })))),
        await lengthCheck(inputs.last_name || '', 40).then(() => status = true).catch((err) => (status = false, setErrors((s) => ({ ...s, last_name: err })))),

        await RegexMatch(inputs.first_name || '', /^[a-zA-Z ]*$/).then(() => status = true).catch(() => (status = false, setErrors((s) => ({ ...s, first_name: 'First name should have only alphabets' })))),
        await RegexMatch(inputs.last_name || '', /^[a-zA-Z ]*$/).then(() => status = true).catch(() => (status = false, setErrors((s) => ({ ...s, last_name: 'Last name should have only alphabets' })))),
        await RegexMatch(inputs.email || '', /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).then(() => status = true).catch(() => (status = false, setErrors((s) => ({ ...s, email: 'Invalid email format' })))),
        await RegexMatch(inputs.phone_number || '', /^[0-9]{9}$/).then(() => status = true).catch(() => (status = false, setErrors((s) => ({ ...s, phone_number: 'Invalid phone number' })))),

        await RegexMatch(inputs.first_name || '', /^$/).then(() => (status = false, setErrors((s) => ({ ...s, first_name: 'Please fill in the mandatory fields' })))).catch(() => status = true),
        await RegexMatch(inputs.last_name || '', /^$/).then(() => (status = false, setErrors((s) => ({ ...s, last_name: 'Please fill in the mandatory fields' })))).catch(() => status = true),
        await RegexMatch(inputs.email || '', /^$/).then(() => (status = false, setErrors((s) => ({ ...s, email: 'Please fill in the mandatory fields' })))).catch(() => status = true),
        await RegexMatch(inputs.phone_number || '', /^$/).then(() => (status = false, setErrors((s) => ({ ...s, phone_number: 'Please fill in the mandatory fields' })))).catch(() => status = true),
    ]).then(() => {
        return status
    })
}

export const onNextButtonValidation = async (inputs, setErrors) => {
    let status = false
    return Promise.all([
        await RegexMatch(inputs.entity || '', /^[a-zA-Z0-9 ]+$/).then(() => status = true).catch((err) => (status = false, setErrors((s) => ({ ...s, entity: 'Acceptable format is alphabets and numbers only' })))),
        await RegexMatch(inputs.reported_elsewhere || '', /^[a-zA-Z]+$/).then(() => status = true).catch((err) => (status = false, setErrors((s) => ({ ...s, reported_elsewhere: err })))),
        await RegexMatch(inputs.description || '', /^[a-zA-Z]+$/).then(() => status = true).catch((err) => (status = false, setErrors((s) => ({ ...s, description: err })))),

        await RegexMatch(inputs.incident_type || '', /^$/).then(() => (status = false, setErrors((s) => ({ ...s, incident_type: 'Please fill in the mandatory fields' })))).catch(() => status = true),
        await RegexMatch(inputs.entity || '', /^$/).then(() => (status = false, setErrors((s) => ({ ...s, entity: 'Please fill in the mandatory fields' })))).catch(() => status = true),
        await RegexMatch(inputs.reported_elsewhere || '', /^$/).then(() => (status = false, setErrors((s) => ({ ...s, reported_elsewhere: 'Please fill in the mandatory fields' })))).catch(() => status = true),
        await RegexMatch(inputs.description || '', /^$/).then(() => (status = false, setErrors((s) => ({ ...s, description: 'Please fill in the mandatory fields' })))).catch(() => status = true),
    ]).then(() => {
        return status
    })
}
