import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Aos from "aos";
import "aos/dist/aos.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getContactInfo, submitContactMessages } from "../redux/actionCreator";
import { contactInfo_details } from "../redux/commonReducer";
import { API_BASE_URL } from "../constants/configuration";
import { useForm } from "react-hook-form";
import Toast from "react-bootstrap/Toast";
function Contact() {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const { t } = useTranslation();
  const [language, setLanguage] = useState("");
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  //
  const [title, setTitle] = useState("");
  const [textareaheight, setTextareaheight] = useState("");

  useEffect(() => {
    document.title = title;
  }, [title]);
  // DATAS
  const contactInfo = useSelector(contactInfo_details);

  useEffect(() => {
    dispatch(getContactInfo());
  }, []);

  useEffect(() => {
    setTitle(t("html_title.contact"));
    const lang = localStorage.getItem("lang");
    setLanguage(lang);
  }, [localStorage.getItem("lang")]);

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    setLanguage(lang);
    Aos.init({ duration: 2000 });
  }, []);

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("name", data?.name);
    formData.append("email", data?.email);
    formData.append("title", data?.title);
    formData.append("message", data?.description);
    dispatch(
      submitContactMessages(formData, (res) => {
        if (res === true) {
          setShow(true);
        }
        reset();
      })
    );
  };

  function autoHeight(e) {
    setTextareaheight(e.target.scrollHeight);
  }
  return (
    <>
      <Header />
      <section className="innerpage__header">
        <div className="container">
          <div className="banner__image">
            {contactInfo?.map((ele) => {
              return (
                <img
                  src={API_BASE_URL + "ContactInfo/" + ele?.banner_name}
                  className="w-100 about-img sm-contact"
                  alt=""
                />
              );
            })}
            <div className="contents">
              <div className="row">
                <div className="col-md-12 p-0">
                  <div className="header__title bottomPad sm-height">
                    <h2>{t("main_title.contact")}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Contact__informations bg-default">
        <div className="container">
          <div className="contact__info mb-5">
            <div className="row justify-content-center">
              {contactInfo?.map((ele) => {
                return (
                  <div className="col-md-5 mb-5">
                    <div className="contact__grid" data-aos="fade-right">
                      <h4>{t("location")}</h4>
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            language == "en"
                              ? ele?.address_en
                              : ele?.address_ar,
                        }}
                      ></p>
                    </div>
                    <div className="contact__grid" data-aos="fade-right">
                      <h4>{t("contact_details")}</h4>
                      <p className="mb-0">
                        <a href={`tel:${ele?.telephone}`}>
                          Tel: +{ele?.telephone}
                        </a>
                      </p>
                      <p className="mt-0">
                        {" "}
                        <a href={`mailto:${ele.email}`}>
                          {language == "en" ? ele?.email : ele?.email}
                        </a>
                      </p>
                    </div>
                  </div>
                );
              })}
              <div className="col-md-5 mb-5">
                <div className="contact__grid" data-aos="fade-left">
                  <h4>{t("send_message")}</h4>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="contact__form">
                      <div class="form-group">
                        <input
                          type="text"
                          {...register("name", { required: true })}
                          class="form-control"
                          placeholder={t("name")}
                        />
                        {errors.name?.type === "required" && (
                          <span className="formError">Name is required</span>
                        )}
                      </div>
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          {...register("email", {
                            required: true,
                            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          })}
                          placeholder={t("email")}
                        />
                        {errors.email?.type === "required" && (
                          <span className="formError">Email is required</span>
                        )}
                        {errors.email?.type === "pattern" && (
                          <span className="formError">
                            Please type valid email
                          </span>
                        )}
                      </div>
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          {...register("title", { required: true })}
                          placeholder={t("title")}
                        />
                        {errors.title?.type === "required" && (
                          <span className="formError">Title is required</span>
                        )}
                      </div>
                      <div class="form-group">
                        <textarea
                          class="form-control"
                          {...register("description", { required: true })}
                          placeholder={t("message")}
                          onChange={autoHeight}
                          rows={1}
                          style={{ height: `${textareaheight}px` }}
                        ></textarea>
                        {errors.description?.type === "required" && (
                          <span className="formError">Message is required</span>
                        )}
                      </div>
                      <button className="btn btn-primary" type="submit">
                        {t("send")}
                      </button>
                    </div>
                    <Toast
                      onClose={() => setShow(false)}
                      show={show}
                      delay={3000}
                      autohide
                    >
                      <Toast.Body>{t("success_mesaage")}</Toast.Body>
                    </Toast>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Contact;
