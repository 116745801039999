import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import Header from '../components/Header'
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
// import required modules
import { EffectFade, Pagination, Navigation, Autoplay } from "swiper";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { banner_lists, getHome_lists, rolesAssign_lists, visionMision_lists } from '../redux/commonReducer';
import { getBanner, getHome, getRolesAssign, getVisionMission } from '../redux/actionCreator';
import { API_BASE_URL } from '../constants/configuration';
import Footer from '../components/Footer';

function Home() {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const [language, setLanguage] = useState('')


  // 
  const [title, setTitle] = useState("");

  useEffect(() => {
    document.title = title;
  }, [title]);


  // DATAS
  const BannerLists = useSelector(banner_lists)
  const visionMisionLists = useSelector(visionMision_lists)
  const getHomeLists = useSelector(getHome_lists)
  const rolesAssign = useSelector(rolesAssign_lists)


  useEffect(() => {
    dispatch(getBanner())
    dispatch(getVisionMission())
    dispatch(getRolesAssign())
    dispatch(getHome())
  }, [])


  useEffect(() => {
    setTitle(t('html_title.home'))
    const lang = localStorage.getItem('lang')
    setLanguage(lang)
  }, [localStorage.getItem('lang')])


  return (
    <>
      <Header />
      <section className="economic__banner">
        <div className="container sm-pad-0">
          <div className="banner__slider">
            <Swiper
              spaceBetween={30}
              effect={"fade"}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false
              }}
              modules={[EffectFade, Autoplay, Pagination]}
              className="mySwiper"
            >
              {
                BannerLists?.map((ele, i) => {
                  return (
                    <SwiperSlide>
                      <div className="banner__contents">
                        <img src={API_BASE_URL + 'Banner/' + ele?.image_name} />
                        <h2>{language == 'en' ? ele?.title_en : ele?.title_ar}</h2>
                      </div>
                    </SwiperSlide>
                  )
                })
              }
            </Swiper>
            <div className="vision__mission">
              {
                visionMisionLists?.map((ele, i) => {
                  return (
                    <>
                      <div className="gridItem">
                        <h4>{language == 'en' ? ele?.mission_title_en : ele?.mission_title_ar}</h4>
                        <p dangerouslySetInnerHTML={{ __html: language == 'en' ? ele?.mission_detail_en : ele?.mission_detail_ar}}></p>
                      </div>
                      <div className="gridItem">
                        <h4>{language == 'en' ? ele?.vision_title_en : ele?.vision_title_ar}</h4>
                        <p dangerouslySetInnerHTML={{ __html: language == 'en' ? ele?.vision_detail_en : ele?.vision_detail_ar}}></p>
                      </div>
                    </>
                  )
                })
              }
            </div>
          </div>
        </div>
        <div className="container">
          <div className="roles__assignments">
            <div className="row bg-white">
              <div className="col-md-3 p-0 col-tab-4">
                      <div className="about__Section">
                        <div className="icon">
                          <img src={API_BASE_URL + 'Home/' + getHomeLists?.image_name} alt="" />
                        </div>
                        <div className="contents">
                          <h5>{language == 'en' ? getHomeLists?.title_en : getHomeLists?.title_ar}</h5>
                          <p dangerouslySetInnerHTML={{ __html: language == 'en' ? getHomeLists?.detail_en : getHomeLists?.detail_ar}}></p>
                          
                        </div>
                      </div>
              </div>
              <div className="col-md-9 p-0 col-tab-8">
                <div className="assigments__section">
                  <h3>{t('roles')}</h3>
                  <div className="navigation__default">
                    <i class="fas fa-arrow-left"></i>
                    <i class="fas fa-arrow-right"></i>
                  </div>
                  <Swiper
                    spaceBetween={10}
                    loop={true}
                    modules={[Navigation]}
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 1,
                        spaceBetween: 40,
                      },
                      1024: {
                        slidesPerView: 2,
                        spaceBetween: 50,
                      },
                    }}
                    navigation={{
                      prevEl: '.fa-arrow-left',
                      nextEl: '.fa-arrow-right',
                    }}
                    className="mySwiper"
                  >
                    {
                      rolesAssign?.map((ele) => {
                        return (
                          <SwiperSlide>
                            <Link to='/'>
                              <div className="section__grids">
                                <div className="row">
                                  <div className="col-md-4 ">
                                    <div className="image"><img src={API_BASE_URL + 'RolesAssign/' + ele?.image_name} className='w-100' alt="" /></div>
                                  </div>
                                  <div className="col-md-8">
                                    <div className="section__contents">
                                      <h5>{language == 'en' ? ele?.title_en : ele?.title_ar}</h5>
                                      <p dangerouslySetInnerHTML={{ __html: language == 'en' ? ele?.detail_en : ele?.detail_ar }}></p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </SwiperSlide>
                        )
                      })
                    }
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className='home-footer'>
        <Footer/>
      </div>

    </>
  )
}

export default Home