import React, { useEffect } from 'react'
import { Col, Nav, Row, Tab } from 'react-bootstrap'
import Header from '../components/Header'
import Footer from '../components/Footer'
import JurisdictionTab from '../components/JurisdictionTab'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { jurisdictionAuthorities_details } from '../redux/commonReducer'
import { getJurisdictionAuthorities } from '../redux/actionCreator'
import { API_BASE_URL } from '../constants/configuration'

function Jurisdictions() {
    const { t } = useTranslation();
    const [language, setLanguage] = useState('')
    const [opentab, setOpentab] = useState('')
    const [imageChange, setImageChange] = useState('')
    const dispatch = useDispatch()
    // 
    const [title, setTitle] = useState("");

    useEffect(() => {
        document.title = title;
    }, [title]);
    // DATAS
    const jurisdictionAuthorities = useSelector(jurisdictionAuthorities_details)

    useEffect(() => {
        dispatch(getJurisdictionAuthorities())
    }, [])

    useEffect(() => {
        setTitle(t('html_title.jurisdictions'))
        const lang = localStorage.getItem('lang')
        setLanguage(lang)
    }, [localStorage.getItem('lang')])


    useEffect(() => {
        const lang = localStorage.getItem('lang')
        setLanguage(lang);
    }, []);

    useEffect(() => {
        if(jurisdictionAuthorities?.length){
            setImageChange(jurisdictionAuthorities[0]?.banner_name)
        }
    }, [jurisdictionAuthorities]);


    const handleTabSelect = (key) => {
        setImageChange(jurisdictionAuthorities[key]?.banner_name)
    };


    return (
        <>
            <Header />
            <section className="innerpage__header">
                <div className="container sm-pad-0">
                    <div className="banner__image">
                    <img src={API_BASE_URL + 'JurisdictionAuthorities/' + imageChange} className='w-100' alt="" />
                        <div className="contents">
                            <div className="row">
                                <div className="col-md-12 p-0">
                                    <div className="header__title bottomPad">
                                        <h2>{t('jurisdictions')}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="roles__assignments bg-default">
                <div className="container">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="0" onSelect={handleTabSelect}>
                        <Nav variant="pills" className="flex-column">
                            <div className="jurisdiction assignment__tabItems">
                                <Row className='nav-scroll'>
                                {
                                        jurisdictionAuthorities?.map((ele,i) => {
                                            return (
                                                <Col md={3} sm={68} className='col-tab-3'>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={`${i}`}>
                                                            <div className="assignment__tab">
                                                                <div className="icon"><img src={API_BASE_URL + 'JurisdictionAuthorities/' + ele?.body_icon_name} alt="" /></div>
                                                                <div className="title">
                                                                    <h5>{language == 'en' ? ele?.sub_title_en : ele?.sub_title_ar}</h5>
                                                                    <h3>{language == 'en' ? ele?.title_en : ele?.title_ar}</h3>
                                                                </div>
                                                            </div>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </Col>
                                            )
                                        })
                                    }
                                 
                                </Row>
                            </div>
                        </Nav>



                        <Tab.Content>
                        {
                                jurisdictionAuthorities?.map((ele,i) => {
                                    return (
                                        <Tab.Pane eventKey={`${i}`}>
                                            <JurisdictionTab data={ele} />
                                        </Tab.Pane>
                                    )
                                })
                            }

                        </Tab.Content>
                    </Tab.Container>

                </div>
            </section>
            <Footer />

        </>
    )
}

export default Jurisdictions