import React, { useEffect, useState } from 'react'
import Aos from "aos";
import "aos/dist/aos.css";
import { API_BASE_URL } from '../constants/configuration';

function AssignmentTab({data}) {
    const [language, setLanguage] = useState('')
    useEffect(() => {
        const lang = localStorage.getItem('lang')
        setLanguage(lang)
    }, [localStorage.getItem('lang')])


    useEffect(() => {
        const lang = localStorage.getItem('lang')
        setLanguage(lang);
    }, []);


    useEffect(() => {
        Aos.init({ duration: 2000 });
      }, []);

  return (
    <>
     <div className="assignmenttab__contents">
     <div className="container p-0">
        <div className="row">
            <div className="col-md-6 p-0">
                <div className="assignment__image" data-aos="fade-right">
                    <img src={API_BASE_URL + 'RolesAssign/' + data?.image_name} className='w-100' alt="" />
                </div>
            </div>
            <div className="col-md-6 p-5">
                <div className="assignment__contents" data-aos="fade-left">
                    <div className="icon"><img src={API_BASE_URL + 'RolesAssign/' + data?.title_icon_name} alt="" /></div>
                    <div className="title">
                        <h6>{language == 'en' ? data?.sub_title_en : data?.sub_title_ar}</h6>
                        <h2>{language == 'en' ? data?.title_en : data?.title_ar}</h2>
                    </div>
                    <div className="description">
                    <p dangerouslySetInnerHTML={{ __html: language == 'en' ? data?.detail_en : data?.detail_ar }}></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
     </div>

    </>
  )
}

export default AssignmentTab